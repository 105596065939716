<template lang="pug">
  v-container
    v-card(
      min-height="80vh"
    )
      router-view(
        @convert-to-pr="convertToPR"
        :pr-items="prItems"
      )
</template>
<script>
export default {
  name: 'CustomerForecast',
  data () {
    return {
      prItems: [],
    }
  },
  methods: {
    convertToPR (items) {
      this.prItems = items
    },
  },
}
</script>
